<template>
  <b-card no-body>
    <b-card-header>
      <div class="d-flex align-items-center">
        <b-avatar
          class="my-1"
          :variant="`light-${color}`"
          size="45"
        >
          <feather-icon
            size="21"
            :icon="icon"
          />
        </b-avatar>
        <div class="ml-1">
          <h2 class="mb-0 font-weight-bolder">
            {{ statistic }}
          </h2>
          <span>{{ statisticTitle }}</span>
        </div>
      </div>
      <b-badge
        variant="primary"
      >
        <b-link
          :to="{ name: link}"
        >
          watch
        </b-link>
      </b-badge>
    </b-card-header>
    <b-card-body class="pb-0 pt-1">
      <vue-apex-charts
        type="area"
        height="100"
        width="100%"
        :options="chartOptionsComputed"
        :series="chartData"
      />
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardBody,
  BAvatar,
  BCardHeader,
  BLink,
  BBadge,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'
import { areaChartOptions } from './chartOptions'

export default {
  components: {
    VueApexCharts,
    BCard,
    BCardBody,
    BAvatar,
    BCardHeader,
    BLink,
    BBadge,
  },
  props: {
    icon: {
      type: String,
      required: true,
    },
    statistic: {
      type: [Number, String],
      required: true,
    },
    statisticTitle: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: 'primary',
    },
    chartData: {
      type: Array,
      default: () => [],
    },
    chartOptions: {
      type: Object,
      default: null,
    },
    link: {
      type: String,
      default: null,
    },
  },
  computed: {
    chartOptionsComputed() {
      if (this.chartOptions === null) {
        const options = JSON.parse(JSON.stringify(areaChartOptions))
        options.theme.monochrome.color = $themeColors[this.color]
        return options
      }
      return this.chartOptions
    },
  },
}
</script>
